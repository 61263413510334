import callapi from "./callapi"
import endpoints from "../config/endpoints"

const Embed = {
    auth: {
        new: async function (secret, flow) {
            return callapi({
                method: "post",
                url: endpoints.embed.auth.new(secret),
                params: { flow }
            })
        }
    }
}

export default Embed