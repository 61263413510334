import callapi from "./callapi"
import endpoints from "../config/endpoints"
import Util from "./util"

const Insurance = {
    search: async function (data, access_token) {
        if (access_token) {
            return await callapi({
                method: "post",
                url: endpoints.insurance.search,
                data,
                headers: {
                    "x-access-token": access_token
                }
            })
        } else {
            return await callapi({
                method: "post",
                url: endpoints.insurance.search,
                data
            })
        }
    },
    covers: async function (id, access_token) {
        if (access_token) {
            return await callapi({
                method: "get",
                url: endpoints.insurance.covers(id),
                headers: {
                    "x-access-token": access_token
                }
            })
        } else {
            return await callapi({
                method: "get",
                url: endpoints.insurance.covers(id)
            })
        }
    },
    new: async function (data, access_token) {
        const { searchData, passengerDetails } = data

        for (let i = 0; i < searchData.num_pax.length; i++) {
            const passenger = i + 1
            if (!(i in passengerDetails)) return { error: `Details are required for Passenger ${passenger}.` }
            for (let x of ["title", "first_name", "last_name", "mobile", "email", "gender", "dob", "pincode", "city", "state", "address", "resident", "destination", "occupation", "nominee_title", "nominee_name", "nominee_relation"]) {
                if (!passengerDetails[i][x]) return { error: `${x.split("_").map(s => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()).join(" ")} is required for Passenger ${passenger}` }
            }
            if ([2, 3].includes(searchData.destination_type)) {
                if (!(passengerDetails[i]["passport_country"])) return { error: `Passport Country is required for Passenger ${passenger}` }
                else if (!(passengerDetails[i]["passport_no"])) return { error: `Passport No. is required for Passenger ${passenger}` }
            }
        }

        if (access_token) {
            return await callapi({
                method: "post",
                url: endpoints.insurance.new,
                data,
                headers: {
                    "x-access-token": access_token
                }
            })
        } else {
            return await callapi({
                method: "post",
                url: endpoints.insurance.new,
                data
            })
        }
    },
    payWallet: async function (id, data, access_token) {
        if (access_token) {
            return await callapi({
                method: "post",
                url: endpoints.insurance.payWallet(id),
                data,
                headers: {
                    "x-access-token": access_token
                }
            })
        } else {
            return await callapi({
                method: "post",
                url: endpoints.insurance.payWallet(id),
                data
            })
        }
    },
    payPG: async function (id, access_token) {
        if (access_token) {
            return await callapi({
                method: "post",
                url: endpoints.insurance.payPG(id),
                headers: {
                    "x-access-token": access_token
                }
            })
        } else {
            return await callapi({
                method: "post",
                url: endpoints.insurance.payPG(id)
            })
        }
    },
    confirmPG: async function (id, data, access_token) {
        if (access_token) {
            return await callapi({
                method: "post",
                url: endpoints.insurance.confirmPG(id),
                data,
                headers: {
                    "x-access-token": access_token
                }
            })
        } else {
            return await callapi({
                method: "post",
                url: endpoints.insurance.confirmPG(id),
                data
            })
        }
    },
    status: async function (id, access_token) {
        if (access_token) {
            return await callapi({
                method: "get",
                url: endpoints.insurance.status(id),
                headers: {
                    "x-access-token": access_token
                }
            })
        } else {
            return await callapi({
                method: "get",
                url: endpoints.insurance.status(id)
            })
        }
    },
    policy: async function (id, ismobile, access_token) {
        if (access_token) {
            return await callapi({
                method: "get",
                url: endpoints.insurance.policy(id),
                params: { ismobile },
                headers: {
                    "x-access-token": access_token
                }
            })
        } else {
            return await callapi({
                method: "get",
                url: endpoints.insurance.policy(id),
                params: { ismobile }
            })
        }
    },
    download: async function (id, num, access_token) {
        if (access_token) {
            return await callapi({
                method: "get",
                url: endpoints.insurance.download(id),
                params: { num },
                responseType: 'blob',
                headers: {
                    "x-access-token": access_token
                }
            })
        } else {
            return await callapi({
                method: "get",
                url: endpoints.insurance.download(id),
                params: { num },
                responseType: 'blob'
            })
        }
    },
    cancel: async function (id) {
        return await callapi({
            method: "post",
            url: endpoints.insurance.cancel(id)
        })
    },
    downloadInvoice: async function (id) {
        return await callapi({
            method: "get",
            url: endpoints.insurance.downloadInvoice(id)
        })
    },
    groupInsurance: async function (data) {
        return callapi({
            method: "post",
            url: endpoints.insurance.groupInsurance,
            data
        })
    },
    mailQuotes: async function (data) {
        return callapi({
            method: "post",
            url: endpoints.insurance.mailQuotes,
            data
        })
    },
    scanPassport: async function (file1, file2) {
        return Util.uploadMultiple(endpoints.insurance.scanPassport, [file1, file2].filter(x => !!x), 'passport')
    }
}

export default Insurance