import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './date-input-v2.css'

import { isMobile } from 'react-device-detect';

const DateInput = (props) => {
    const [dateValue, setDateValue] = useState(props?.defaultValue || null)
    const [shownValue, setShownValue] = useState(props?.defaultValue || null)

    function onChange(v) {
        try {
            if (v) {
                let date
                if (isMobile) date = v
                else date = v.getFullYear() + "-" + (v.getMonth() + 1).toString().padStart(2, "0") + "-" + v.getDate().toString().padStart(2, "0")
                setDateValue(date)
            }
        } catch (e) { }
    }

    function onFocusOut(e) {
        if (dateValue) {
            setShownValue(dateValue)
            props.onChange(dateValue)
        }
    }

    return (
        <div className={"relative border rounded-xl border-sk-blue py-3 px-6 has-[:invalid]:border-red flex flex-col-reverse justify-center " + (props.className ? props.className : "")}>
            {isMobile && <input id={props.id} type="date" value={shownValue} className="border-sk-yellow w-full" onChange={v => onChange(v.target.value)} onBlur={onFocusOut} />}
            {!isMobile && <DatePicker id={props.id} clearIcon={null} format='dd/MM/yyyy' value={shownValue} className="border-sk-yellow" calendarIcon={<img className="w-4 h-4" src="/assets/icons/calendar.svg" alt="calendar icon" />} onChange={onChange} onBlur={onFocusOut} />}
            {/* <img className="absolute right-4 top-6 w-4 h-4" src="/assets/icons/calendar.svg" alt="input icon" /> */}
            <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold peer-invalid:text-red">{props.label}</label>
        </div>
    )
}

DateInput.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputRef: PropTypes.any,
    label: PropTypes.string,
    defaultValue: PropTypes.string
}

export default DateInput