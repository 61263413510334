import React from "react"
import { CommonLoader } from "../../../index"

const Loading = () => {
    return (
        <div className="flex flex-col gap-2">
            <span className="text-2xl font-bold">Welcome to Travel Insurance</span>
            <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
                <div className="order-2 sm:order-1 flex flex-col gap-4 items-center sm:items-start mt-4">
                    <span className="text-sm font-medium">Please wait...</span>
                    <div className="flex gap-2 items-center">
                        <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loading