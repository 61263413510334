import React, { useEffect, useState } from 'react'
import API from '../../../../apis'
import { CommonTable } from '../../..'
import { MdOutlineAttachEmail } from "react-icons/md"
import utils from '../../../common/utils'
import MixPanel from '../../../../config/mixpanel'

const InsurancePlanBanner = (props) => {
    const [error, setError] = useState(null)
    const [detail, setDetail] = useState(null)
    const [cover, setCover] = useState(null)

    useEffect(() => {
        const fetchCover = async function () {
            const response = await API.insurance.covers(props.id, props.hideAgentFunctionality ? props.access_token : null)
            if (response.status === 200 && response.data?.data) {
                setCover(response.data.data)
            }
        }
        fetchCover()
    }, [props.id, props.hideAgentFunctionality, props.access_token])

    const showDetails = async function (e) {
        e.stopPropagation()
        MixPanel.track(MixPanel.types.agent.insurance.view_cover, { id: props.id })
        if (cover !== null) {
            setDetail(cover)
            return
        }

        const response = await API.insurance.covers(props.id)
        if (response.status === 200 && response.data?.data) {
            setDetail(response.data.data)
        } else {
            setError(response.error || "API Error!")
        }
    }

    const selectEmailShare = async function (e) {
        e.stopPropagation()
        props.selectEmailShare(props.id, !props.emailShareSelected)
    }

    return (
        <div className={"relative cursor-pointer rounded-2xl shadow p-4 " + (props.active === true ? "border-2 border-sk-blue-select " : "border-2 border-white ") + (props.className || "")} onClick={props.onClick}>
            {props.active && <div className="ribbon text-xs lg:text-sm text-white font-bold">Selected</div>}
            <p className="font-semibold lg:text-lg text-center lg:text-left">{props.heading}</p>

            <div className="mt-3 font-semibold flex items-center">
                <p className="text-sm flex-1 text-gray-700">Sum Insured: </p>
                <p className="flex-none"><span className="text-lg ml-2">{props.destinationType === "1" ? <span>&#8377;</span> : "$"} {utils.formatINR(props.sumInsured)}</span></p>
            </div>

            <div className="mt-1 font-semibold flex items-center">
                {props.hideAgentFunctionality && <p className="text-sm flex-1 text-gray-700">Gross Premium: </p>}
                {!props.hideAgentFunctionality && <p className="text-sm flex-1 text-gray-700">Gross Premium to Customer: </p>}
                <p className="flex-none"><span className="text-lg ml-2">&#8377; {props.price}</span></p>
            </div>

            <div className={"mt-1 font-semibold flex items-center " + (props.agentPrice ? "" : "hidden lg:flex lg:invisible")}>
                <p className="text-sm flex-1 text-gray-700">Net Premium to Agent: </p>
                <p className="flex-none"><span className="text-lg ml-2">&#8377; {props.agentPrice}</span></p>
            </div>

            <p className="mt-6 flex justify-end items-center gap-x-2">
                <button className="bg-sk-yellow rounded-2xl px-8 py-2 text-xs font-medium" onClick={showDetails}>Cover Details</button>
                {!props.hideAgentFunctionality && <span onClick={selectEmailShare}>
                    <MdOutlineAttachEmail className={"inline-block w-8 h-8 rounded-full p-2 " + (props.emailShareSelected ? "bg-sk-blue text-white" : "bg-sk-yellow")} />
                </span>}
                {/* <img src="/assets/icons/share.svg" alt="share" className="float-right inline" /> */}
            </p>
            {detail && <div className="z-40 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto bg-gray-900 bg-opacity-50 flex items-center justify-center cursor-default">
                <div className="bg-white rounded-2xl w-full lg:w-3/4 p-4 lg:p-6 fade-in-top">
                    <p className="text-right cursor-pointer">
                        <img src="/assets/icons/cross.svg" alt="cross" className="inline w-6 h-6 p-1.5 rounded-full bg-gray-100 opacity-50" onClick={() => setDetail(null)} />
                    </p>
                    {error && <p>{error}</p>}
                    <div className="text-center mt-2 h-96 overflow-y-scroll">
                        <p className="font-bold mb-2">{props.heading}</p>
                        <CommonTable data={detail} className="" />
                        {/* <p className="text-left mt-4">Other Terms and Conditions:</p>
                        <ul className="list-disc list-inside text-left">
                            <li>Quote is applicable for domestic travel only.</li>
                            <li>Premium quoted is per life for a Round trip only.</li>
                            <li>Applicable age group for the premium quoted is 1 to 70 years.</li>
                            <li>Mode of travel through air only</li>
                            <li>Below mentioned triggers for Trip cancellation/Trip interruption & Curtailment will not be considered for a valid claim:</li>
                            <li>Trigger 5: A Major Travel Event that prevents You from travelling to one of Your destination(s) in your itinerary</li>
                            <li>Trigger 7: Advisory issued by the government of India not to travel or prevention of travel by government of India</li>
                            <li>Please Note: Claims for the above 2 triggers will not be applicable only if it is related to or arising out of COVID-19 pandemic</li>
                        </ul> */}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default InsurancePlanBanner