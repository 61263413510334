import React, { useEffect, useState } from "react"
import Loading from "./pages/loading"
import InsuranceSearch from "../../agent/insurance/new-insurance/insurance-search"
import InsuranceResult from "../../agent/insurance/new-insurance/insurance-result"
import PassengerDetails from "../../agent/insurance/new-insurance/passenger-details"
import { CommonPopup } from "../.."
import InsuranceReview from "../../agent/insurance/new-insurance/insurance-review"
import InsuranceConfirmation from "../../agent/insurance/new-insurance/insurance-confirmation"
import { useParams, useSearchParams } from "react-router-dom"
import API from "../../../apis"

const InsuranceWidget = () => {
    const { secret } = useParams()
    const [searchParams] = useSearchParams()
    const [page, setPage] = useState(-1)
    const [data, setData] = useState({})
    const [error, setError] = useState(null)
    const [insuranceNumber, setInsuranceNumber] = useState(null)

    async function changeView(err, view, insuranceNumber, data = {}) {
        console.log("changeview", err, view, insuranceNumber, data)
        if (err) setError(err)
        if (view === 0) {
            setPage(0)
            setInsuranceNumber(null)
        } else {
            if (view) setPage(view)
            if (insuranceNumber) setInsuranceNumber(insuranceNumber)
            setData(prev => ({ ...prev, ...data }))
        }
    }

    useEffect(() => {
        if (page === -1) {
            // call embed auth api to start session
            const embedAuth = async function () {
                const response = await API.embed.auth.new(secret, "INSURANCE")
                if (response.status === 200 && response.data?.data) {
                    const init_data = {}
                    if (searchParams.has("trip_type")) init_data.trip_type = searchParams.get("trip_type")
                    else init_data.trip_type = "1"
                    if (searchParams.has("destination_type")) init_data.destination_type = searchParams.get("destination_type")
                    if (searchParams.has("departure_date")) init_data.departure_date = searchParams.get("departure_date")
                    if (searchParams.has("return_date")) init_data.return_date = searchParams.get("return_date")
                    if (searchParams.has("age_pax")) {
                        let age_pax = searchParams.get("age_pax")
                        if (typeof age_pax === "string") {
                            age_pax = decodeURIComponent(age_pax)
                            init_data.num_pax = age_pax.split(",").filter(x => !isNaN(x)).map(x => x * 1)
                        }
                    }
                    setData(prev => ({ ...prev, access_token: response.data?.data, searchData: init_data }))
                    setTimeout(() => {
                        setPage(0)
                    }, 1000)
                } else {
                    setError(response?.error || "Unknown Error")
                }
            }
            embedAuth()
        }
    }, [page, secret, searchParams])

    useEffect(() => {
        console.log("useeffect", data)
    }, [data])

    return (
        <div className="bg-sk-blue-light text-gray-900 flex flex-col w-full h-full">
            <div className="flex flex-col items-center px-4">
                {error && <CommonPopup onOk={() => setError(null)} onCancel={() => setError(null)}><div>{error}</div></CommonPopup>}
                <div className="bg-white p-8 shadow rounded-sm my-4 w-full">
                    {page === -1 && <Loading />}
                    {page === 0 && <div className="mt-7"><InsuranceSearch changeView={changeView} data={data} hideAgentFunctionality={true} /></div>}
                    {page === 1 && <div className="mt-7"><InsuranceResult changeView={changeView} data={data} hideAgentFunctionality={true} /></div>}
                    {page === 2 && <div className="mt-7"><PassengerDetails changeView={changeView} data={data} hideAgentFunctionality={true} /></div>}
                    {page === 3 && <div className="mt-7"><InsuranceReview changeView={changeView} data={data} insuranceNumber={insuranceNumber} hideAgentFunctionality={true} /></div>}
                    {page === 4 && <div className="mt-7"><InsuranceConfirmation insuranceNumber={insuranceNumber} hideAgentFunctionality={true} data={data} /></div>}
                </div>
            </div>
        </div>
    )
}

export default InsuranceWidget