import React, { useEffect, useState } from "react";
import './date-input.css'
import utils from '../utils'
import PropTypes from 'prop-types'

const DateInput = (props) => {
    const [dateValues, setDateValues] = useState([props.value || "", props.value ? utils.formatDate(props.value) : ""])

    function focusInput(e) {
        e.preventDefault()
        document.getElementById(props.id).blur()
        try {
            document.getElementById(props.id + "_date").showPicker()
        } catch (e) {
            console.log(e)
        }
    }

    function onInputFocus(e) {
        e.preventDefault()
        document.getElementById(props.id).blur()
        try {
            document.getElementById(props.id + "_date").showPicker()
        } catch (e) {
            console.log(e)
        }
    }

    function focusOut(e) {
        e.preventDefault()
        document.getElementById(props.id).value = dateValues[1]
    }

    function onDateSelected(e) {
        const currentValue = e.target.value
        const formattedValue = currentValue ? utils.formatDate(currentValue) : ""
        setDateValues([currentValue, formattedValue])
        document.getElementById(props.id).type = "text"
        document.getElementById(props.id).value = formattedValue
        typeof props.onChange === "function" && props.onChange(currentValue)
    }

    useEffect(() => {
        if (props.defaultValue) {
            const currentValue = props.defaultValue
            const formattedValue = currentValue ? utils.formatDate(currentValue) : ""
            setDateValues([currentValue, formattedValue])
            document.getElementById(props.id).type = "text"
            document.getElementById(props.id).value = formattedValue
        }
    }, [props.defaultValue, props.id])

    return (
        <div className={"bg-white relative border rounded-xl border-sk-blue py-3 px-6 has-[:invalid]:border-red flex flex-col-reverse justify-center " + (props.className ? props.className : "")} onClick={focusInput}>
            <input ref={props.inputRef || null} id={props.id + "_date"} type="date" className="h-0 text-white" onChange={onDateSelected} onBlur={focusOut} onFocus={onInputFocus} />
            <input type="text" id={props.id} className="peer focus:outline-none font-medium inline-block w-full py-0" onClick={focusInput} defaultValue={props.value ? utils.formatDate(props.value) : ""} />
            <img className="absolute right-4 top-6 w-4 h-4" src="/assets/icons/calendar.svg" alt="input icon" />
            <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold peer-invalid:text-red">{props.label}</label>
        </div>
    )
}

DateInput.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputRef: PropTypes.any,
    label: PropTypes.string,
    defaultValue: PropTypes.string
}

export default DateInput