import React, { useState, useEffect } from 'react'
import { CommonFormButton, CommonFormCheckbox, UseScript, CommonTooltip, CommonPopup, CommonFormTextInput, CommonError } from '../../../index'
import Constants from '../../../../config/constants'
import Passenger from './passenger-detail-static'
import API from '../../../../apis'
import MixPanel from '../../../../config/mixpanel'
import { IoIosInformationCircleOutline } from "react-icons/io"
import utils from '../../../../config/utils'

const InsuranceReview = (props) => {
    UseScript("https://checkout.razorpay.com/v1/checkout.js")
    const { searchData, selectedPlan, passengerDetails } = props.data
    const [checks, setChecks] = useState([true, false])
    const [showWallet, setShowWallet] = useState(true)
    const [localPromocode, setLocalPromocode] = useState(null)
    const [promocode, setPromocode] = useState(null)
    const [showPromoCode, setShowPromoCode] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (props.hideAgentFunctionality) return setShowWallet(false)
        const walletBalance = window.sessionStorage.getItem(Constants.SessionVariables.WalletBalance) * 1 || 0
        if (promocode) {
            if (promocode.calc_amount_raw > walletBalance) setShowWallet(false)
            else setShowWallet(true)
        } else {
            if (selectedPlan.net_amount_raw > walletBalance) setShowWallet(false)
            else setShowWallet(true)
        }
    }, [selectedPlan.net_amount_raw, selectedPlan.net_amount, promocode, props.hideAgentFunctionality])

    const pay = async function (type) {
        if (!checks[0] || !checks[1]) {
            props.changeView("Please accept all declarations.")
            return
        }
        if (type === "wallet") {
            const response = await API.insurance.payWallet(props.insuranceNumber, { promo_code: promocode?.promo_code }, props.hideAgentFunctionality ? props.data.access_token : null)
            if (response.status === 200 && response.data?.data) {
                props.changeView(null, 4, null, {})
                MixPanel.track(MixPanel.types.agent.insurance.review_payment)
                utils.updateSessionVar(Constants.SessionVariables.WalletBalance, response.data?.data)
            } else {
                props.changeView(response.error || "API Error!")
            }
        } else if (type === "pg") {
            // const response = await API.insurance.payPG(props.insuranceNumber)
            const data = { amount: promocode ? promocode.calc_amount_raw : selectedPlan.net_amount_raw, remarks: "Payment for " + props.insuranceNumber }
            const response = await API.wallet.newPayment(data, props.hideAgentFunctionality ? props.data.access_token : null)
            if (response.status === 200 && response.data.data) {
                openRazorpay(response.data.data.key, response.data.data.order_id, response.data.data.mobile)
            } else {
                props.changeView(response.error || "API Error!")
            }
        }
    }

    async function openRazorpay(key, orderId, mobile) {
        var options = {
            "key": key,
            "amount": (promocode ? promocode.calc_amount_raw : selectedPlan.net_amount_raw) * 100,
            "currency": "INR",
            "name": "SanKash Pvt Ltd",
            "description": "Payment for " + props.insuranceNumber,
            "image": Constants.SANKASH_LOGO_URL,
            "order_id": orderId,
            "prefill": {
                "contact": mobile
            },
            "handler": confirmPayment,
            "theme": {
                "color": "#01BAFA"
            },
            // "config": {
            //     "display": {
            //         "hide": [
            //             { "method": "emi" },
            //             { "method": "credit" }
            //         ],
            //         "preferences": { show_default_blocks: true }
            //     },
            // }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', paymentFailure);
        rzp1.open()
    }

    async function paymentFailure(response) {
        props.changeView(response.error.description || "PG Error!")
    }

    async function confirmPayment({ razorpay_payment_id, razorpay_order_id, razorpay_signature }) {
        const response = await API.wallet.confirmPayment({ payment_id: razorpay_payment_id, order_id: razorpay_order_id, signature: razorpay_signature }, props.hideAgentFunctionality ? props.data.access_token : null)
        if (response.status === 200) {
            pay("wallet")
        } else {
            props.changeView(response.error || "API Error!")
        }
    }

    // useEffect(() => console.log(promocode), [promocode])

    function showPromo() {
        setPromocode(null)
        setShowPromoCode(true)
    }

    function hidePromo() {
        setError(null)
        setLocalPromocode(null)
        setShowPromoCode(false)
    }

    async function checkPromoCode() {
        setError(null)
        const response = await API.wallet.checkPromoCode({ promo_code: localPromocode, net_amount: selectedPlan.net_amount_raw, premium: selectedPlan.premium })
        if (response.status === 200 && response.data?.data?.promo_amount) {
            setPromocode({ promo_code: localPromocode, promo_amount: response.data?.data?.promo_amount, calc_amount: response.data?.data?.calc_amount, calc_amount_raw: response.data?.data?.calc_amount_raw })
            setShowPromoCode(false)
            MixPanel.track(MixPanel.types.agent.insurance.promotion_applied)
        } else {
            setError(response.error || "API Error!")
        }
    }

    async function removePromoCode() {
        setPromocode(null)
        setLocalPromocode(null)
    }

    return (
        <div>
            <div className="py-8 px-8 bg-sk-yellow rounded-2xl flex gap-3 flex-col lg:flex-row text-sm lg:text-base">
                <div className="flex-1">
                    <p className="font-bold text-xl">{selectedPlan.plan_name}</p>
                    <p className="font-semibold">{Constants.INSURANCE.TRIP_TYPE[searchData.trip_type]}</p>
                    <p className="font-semibold">{Constants.INSURANCE.DESTINATION_TYPE[searchData.destination_type]}</p>
                </div>
                <div className="flex-1">
                    <p className="font-semibold">Start Date: {searchData.departure_date}</p>
                    {searchData.return_date && <p className="font-semibold">End Date: {searchData.return_date}</p>}
                    <p className="font-semibold">No. of Pax: {searchData.num_pax.length}</p>
                </div>
                <div className="flex-none">
                    <button type="button" className="bg-sk-blue rounded-2xl px-4 py-2 text-white text-sm font-semibold" onClick={() => props.changeView(null, 1, null, {})}>Choose another plan</button>
                </div>
            </div>
            <div className="mt-8 flex items-center lg:items-start flex-col-reverse lg:flex-row gap-8">
                <div className="flex-1">
                    {searchData.num_pax.map((x, i) => <div className="mb-4" key={i}>
                        <Passenger i={i} x={x} searchData={searchData} passengerDetails={passengerDetails[i]} />
                    </div>)}
                    <div className='grid grid-cols-2 gap-6 px-6'>
                        <p className="col-span-2 mt-6 font-bold">Declarations</p>
                        {/* <div className="col-span-2 flex gap-4">
                            <CommonFormCheckbox className="flex-none w-4 h-4 mt-1" checked={checks[0]} onChange={v => setChecks(prev => [v, prev[1]])} />
                            <p onClick={() => setChecks(prev => [!prev[0], prev[1]])}>My family members are free from COVID-19 or its signs/symptoms. We neither travelled internationally (last 15 days) nor my residence is declared as sealed/quarantine zone(last 15 days).</p>
                        </div> */}
                        <div className="col-span-2 flex gap-4">
                            <CommonFormCheckbox className="flex-none w-4 h-4 mt-1" checked={checks[1]} onChange={v => setChecks(prev => [prev[0], v])} />
                            <p onClick={() => setChecks(prev => [prev[0], !prev[1]])}>I have reviewed and agree to <a target="_blank" rel="noreferrer" href="https://sankash.in/assets/documents/insurance-terms-and-conditions-icici.pdf" className="text-sk-blue">Terms & Conditions</a>.</p>
                        </div>
                        <p className="col-span-2 text-red text-sm my-6">
                            Note: A cancellation charge of Rs. 0 is charged for each policy cancellation up to 24 hours before departure date.
                        </p>
                    </div>

                    <div className="my-4 flex gap-8">
                        {showWallet === true && <CommonFormButton text={`Pay with Wallet`} className="w-full" onClick={() => pay("wallet")} />}
                        {(!promocode || promocode.calc_amount_raw > 0) && <CommonFormButton text="Pay with Payment Gateway" className="w-full" onClick={() => pay("pg")} />}
                    </div>
                </div>
                <div className="flex-none w-full lg:w-80 flex-col mb-8">
                    <div className="flex justify-between">
                        <p className={"text-sm font-medium mb-4 cursor-pointer " + (promocode ? "" : "underline underline-offset-4")} onClick={showPromo}>{promocode ? "Promo Code Applied:" : "Have Promo Code?"}</p>
                        {promocode && <p>{promocode?.promo_code} <img src="/assets/icons/cross.svg" alt="cross" className="inline w-6 h-6 p-1.5 rounded-full bg-gray-100 opacity-50 cursor-pointer" onClick={removePromoCode} /></p>}
                    </div>
                    <div className="bg-sk-yellow rounded-t-2xl">
                        <p className="px-8 flex py-4 font-bold items-center">
                            <span className="flex-none text-lg">Price Details</span>
                            <span className="flex-grow"></span>
                        </p>
                        <div className="bg-gray-50 rounded-t-2xl px-8 flex justify-between pt-6 font-medium">
                            <span className="float-none flex">
                                Gross Premium
                                <CommonTooltip width="w-64" bottomLeft={true}>
                                    <IoIosInformationCircleOutline className="text-black" />
                                    <p className="mt-1">Gross premium of policy inclusive of 18% GST.</p>
                                </CommonTooltip>
                            </span>
                            <span className="flex-none">&#8377; {selectedPlan.premium}</span>
                        </div>
                        <div className="bg-gray-50 px-8 flex justify-between pt-6 font-medium">
                            <span className="float-none flex">
                                Discount
                                <CommonTooltip width="w-64" bottomLeft={true}>
                                    <IoIosInformationCircleOutline className="text-black" />
                                    <p className="mt-1">{selectedPlan.discountPercent}% commission on net premium (Gross premium - GST).</p>
                                </CommonTooltip>
                                &nbsp;( - )
                            </span>
                            <span className="flex-none">&#8377; {selectedPlan.discount}</span>
                        </div>
                        <div className="bg-gray-50 px-8 flex justify-between py-6 font-medium">
                            <span className="float-none flex">
                                TDS
                                <CommonTooltip width="w-64" bottomLeft={true}>
                                    <IoIosInformationCircleOutline className="text-black" />
                                    <p className="mt-1">2% of discount is deducted as TDS. To claim the TDS please write with your PAN number to insurance@sankash.in</p>
                                </CommonTooltip>
                                &nbsp;( + )
                            </span>
                            <span className="flex-none">&#8377; {selectedPlan.tds}</span>
                        </div>
                        {promocode && <div className="bg-gray-50 px-8 flex justify-between pb-6 font-medium">
                            <span className="float-none flex">
                                Promo Discount
                                &nbsp;( - )
                            </span>
                            <span className="flex-none">&#8377; {promocode?.promo_amount}</span>
                        </div>}
                        <div className="px-8 flex py-6 justify-between font-bold">
                            <span className="float-none flex">
                                Net Payment
                                <CommonTooltip width="w-64" bottomLeft={true}>
                                    <IoIosInformationCircleOutline className="text-black" />
                                    <p className="mt-1">Only pay premium net of commission.</p>
                                </CommonTooltip>
                            </span>
                            <span className="flex-none">&#8377; {promocode ? promocode.calc_amount : selectedPlan.net_amount}</span>
                        </div>
                    </div>
                </div>
            </div>
            {showPromoCode && <CommonPopup sticky={true} onOk={checkPromoCode} onCancel={hidePromo}>
                <p className="mb-2">Enter Promo Code:</p>
                <CommonFormTextInput minLength="5" maxLength="6" id="sk-insurance-promo-code" onChange={setLocalPromocode} placeholder="XXXXXX" value={localPromocode || ""} />
                {error && <CommonError error={error} className="mt-2" />}
            </CommonPopup>}
        </div>
    )
}

export default InsuranceReview