import React, { useEffect, useState } from "react";
import { CommonError, CommonFormButton, CommonFormCheckboxV2, CommonFormSearchListInput, CommonFormSelect, CommonTable } from "../..";
import API from "../../../apis";
import { FiPlus, FiMinus } from "react-icons/fi";

const PartnerConfig = () => {
    const [error, setError] = useState(null)
    const [partner, setPartner] = useState(null)
    const [tripType, setTripType] = useState(null)
    const [destinationType, setDestinationType] = useState(null)
    const [data, setData] = useState(null)
    const [updates, setUpdates] = useState({})

    useEffect(() => {
        setError(null)
        setData(null)
        setUpdates({})
    }, [partner, tripType, destinationType])

    const search = async function () {
        if (!partner || !tripType || !destinationType) return
        setError(null)
        setData(null)
        const response = await API.backoffice.manage.insurance.get(partner, tripType, destinationType)
        if (response.status === 200 && response.data?.data) {
            if (response.data.data.length === 0) {
                setError(`No plans found.`)
                return
            }
            const data = {}
            for (let dp of response.data?.data) {
                const plan_name = dp.plan_name
                if (!(plan_name in data)) data[plan_name] = { plans: [], expanded: false }
                delete dp.plan_name
                data[plan_name].plans.push(dp)
            }
            for (let plan_name in data) {
                data[plan_name].status = data[plan_name].plans[0].status
                // for (let plan of data[plan_name].plans) {
                //     if (plan.status !== data[plan_name].status) {
                //         setError(`Invalid config for plan id ${plan.id} for this partner.`)
                //         return
                //     }
                // }
            }
            setData(data)
        } else {
            setError(response.error || "API Error!")
        }
    }

    const expand = async function (plan_name, expand) {
        setData(x => {
            const y = { ...x }
            y[plan_name].expanded = expand
            return y
        })
    }

    const change = async function (plan_name, checked) {
        if (data[plan_name].status === 1) {
            if (checked) {
                setUpdates(x => {
                    const y = { ...x }
                    delete y[plan_name]
                    return y
                })
            } else {
                setUpdates(x => {
                    const y = { ...x }
                    y[plan_name] = 0
                    return y
                })
            }
        } else if (data[plan_name].status === 0) {
            if (checked) {
                setUpdates(x => {
                    const y = { ...x }
                    y[plan_name] = 1
                    return y
                })
            } else {
                setUpdates(x => {
                    const y = { ...x }
                    delete y[plan_name]
                    return y
                })
            }
        }
    }

    const reset = async function () {
        setUpdates({})
    }

    const update = async function () {
        if (Object.keys(updates).length === 0) return
        const updateData = {}
        for (let plan_name in updates) {
            for (let plan of data[plan_name].plans) {
                updateData[plan.id * 1] = updates[plan_name]
            }
        }
        const response = await API.backoffice.manage.insurance.post(partner, updateData)
        if (response.status === 200) {
            setUpdates({})
            search()
        } else {
            setError(response.error || "API Error!")
        }
    }

    return (
        <div className="flex flex-col gap-4">
            <h6 className="font-semibold hamburger-spacing">Partner Insurance Config</h6>
            <hr className="mt-3 text-gray" />
            <div className="flex gap-4 mt-2 *:flex-1">
                <CommonFormSearchListInput id="sk-ti-partner-config-psk" label="Partner PSK ID" searchPlaceholder="PSK-XXXX" searchAPI={API.backoffice.partner.search} onChange={setPartner} />
                <CommonFormSelect id="sk-ti-partner-config-tt" label="Trip Type" onChange={setTripType} className="col-span-6 lg:col-span-3 xl:col-span-2 xl:col-start-1" >
                    <option value="1">Single Trip</option>
                    <option value="2">Multi Trip</option>
                </CommonFormSelect>
                <CommonFormSelect id="sk-ti-partner-config-dt" label="Destination Type" onChange={setDestinationType} className="col-span-6 lg:col-span-3 xl:col-span-2 xl:col-start-1" >
                    <option value="1">Domestic</option>
                    <option value="2">Worldwide with US & Canada</option>
                    <option value="3">Worldwide without US & Canada</option>
                </CommonFormSelect>
                <CommonFormButton disabled={!(partner && tripType && destinationType)} className="px-4" onClick={search} text="Search" />
            </div>

            {error && <CommonError error={error} />}

            {data && <div className="flex flex-col">
                {Object.keys(data).map((plan_name, i) => <div key={i} className="flex flex-col border-b-2 border-gray-100">
                    {i === 0 && <div className="flex px-4 py-2 text-sm">
                        <span className="flex-1">Plan Name</span>
                        <span className="">Active</span>
                    </div>}
                    <div className="flex px-4 py-2 gap-2 hover:bg-gray-100">
                        {data[plan_name].expanded
                            ? <FiMinus className="w-4 h-4 mt-1 cursor-pointer" onClick={expand.bind(this, plan_name, false)} />
                            : <FiPlus className="w-4 h-4 mt-1 cursor-pointer" onClick={expand.bind(this, plan_name, true)} />
                        }
                        <span className="flex-1">{plan_name}</span>
                        <CommonFormCheckboxV2 checked={plan_name in updates ? updates[plan_name] === 1 : data[plan_name].status === 1} label="" onChange={c => change(plan_name, c)} />
                    </div>
                    {data[plan_name].expanded && <CommonTable data={{ display: ["Plan ID", "Insurer", "Product Code", "Plan Code", "Deal Number", "Days Start", "Days End", "Sum Insured"], fields: ["id", "insurer_id", "product_code", "plan_code", "deal_number", "days_start_range", "days_end_range", "sum_insured"], data: data[plan_name].plans }} />}
                </div>)}
            </div>}

            {data && <div className="flex justify-center gap-2">
                <CommonFormButton disabled={Object.keys(updates).length === 0} className="px-4" onClick={update} text="Update" />
                <CommonFormButton disabled={Object.keys(updates).length === 0} className="px-4" onClick={reset} text="Reset" />
            </div>}
        </div>
    )
}

export default PartnerConfig