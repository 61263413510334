import React from 'react'
import PropTypes from 'prop-types'

const TextArea = (props) => {
    function onChange(e) {
        if (typeof props.onChange === "function") props.onChange(e.target.value)
    }

    return (
        <div className={"relative border rounded-xl border-sk-blue py-3 px-6 has-[:invalid]:border-red flex flex-col-reverse justify-center " + (props.className ? props.className : "")}>
            <textarea ref={props.inputRef} id={props.id} defaultValue={props.value || ""} onChange={onChange} className="border border-gray-100 rounded focus:outline-none" maxLength={props.maxLength || 100} minLength={props.minLength || 0} rows={props.numRows || 1}></textarea>
            <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold peer-invalid:text-red">{props.label}</label>
        </div>
    )
}

TextArea.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputRef: PropTypes.any,
    label: PropTypes.string,
    maxLength: PropTypes.string,
    minLength: PropTypes.string,
    numRows: PropTypes.string
}

export default TextArea