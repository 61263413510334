// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { CommonAccordion, CommonFormButton, CommonFormFileInput, CommonTooltip } from '../../../index'
import Constants from '../../../../config/constants'
import Passenger from './passenger-detail'
import API from '../../../../apis'
import { IoIosInformationCircleOutline } from "react-icons/io"
import { RiFileExcel2Fill } from "react-icons/ri";
import MixPanel from '../../../../config/mixpanel'
import { Link } from 'react-router-dom'
// import { BiCollapseVertical, BiExpandVertical } from "react-icons/bi"

const insuranceStaticFile = {
    "1": "https://storage.googleapis.com/sankash-public/cdn/insurance-templates/SanKash-Insurance-Sample-Excel-Upload-D.xlsx",
    "2": "https://storage.googleapis.com/sankash-public/cdn/insurance-templates/SanKash-Insurance-Sample-Excel-Upload-W.xlsx",
    "3": "https://storage.googleapis.com/sankash-public/cdn/insurance-templates/SanKash-Insurance-Sample-Excel-Upload-X.xlsx"
}

const PassengerDetails = (props) => {
    const { searchData, selectedPlan } = props.data
    const [data, setData] = useState({})
    const [confirm, setConfirm] = useState(false)
    const [uploadFile, setUploadFile] = useState(null)

    // useEffect(() => { console.log("selectedplan", selectedPlan) }, [selectedPlan])
    // useEffect(() => { console.log("searchdata", searchData) }, [searchData])
    // useEffect(() => { console.log("props", props) }, [props])

    const passengerDataUpdate = async function (i, k, v) {
        if (confirm) setConfirm(false)
        setData(prev => {
            const newData = { ...prev }
            if (!(i in newData)) newData[i] = {}
            newData[i][k] = v
            return newData
        })
    }

    const copySection = async function (i, keys = []) {
        if (i === 0) return
        setData(prev => {
            const newData = { ...prev }
            if (!(i in newData)) newData[i] = {}
            for (let k of keys) {
                newData[i][k] = newData[0][k]
            }
            return newData
        })
        MixPanel.track(MixPanel.types.agent.insurance.copy_details_from_p1)
    }

    const onSubmit = async function () {
        const response = await API.insurance.new({ searchData, selectedPlan, passengerDetails: data, confirm }, props.hideAgentFunctionality ? props.data.access_token : null)
        if (response.status === 200) {
            if ("updatedPlan" in response.data?.data) {
                // TODO this scenario handling is pending
                searchData.num_pax = response.data?.data?.final_num_pax
                for (let k in response.data.data.updatedPlan) {
                    selectedPlan[k] = response.data?.data?.updatedPlan[k]
                }
                props.changeView("Your premium has changed based on the date of birth provided for passengers. Please check and click on PROCEED again.", null, null, { searchData, selectedPlan })
                setConfirm(true)
            } else if ("insuranceNumber" in response.data?.data) {
                searchData.num_pax = response.data.data.final_num_pax
                props.changeView(null, 3, response.data.data.insuranceNumber, { passengerDetails: data, searchData })
                MixPanel.track(MixPanel.types.agent.insurance.passenger_details)
            } else {

            }
        } else {
            props.changeView(response.error || "API Error!")
        }
    }

    const onUpload = async function () {
        if (!uploadFile) return props.changeView("Please select a file.")

        const response = await API.util.converter.excelToJSON({
            file: uploadFile[0],
            ext: uploadFile[0].name.split(".").pop(),
            size: uploadFile[0].size,
            type: uploadFile[0].type
        }, "insurance-passenger")

        if (response.status === 200 && response.data?.data) {
            const respData = response.data.data
            setData(prev => {
                const newData = { ...prev }
                for (let i = 0; i < searchData.num_pax.length; i++) {
                    if (i in respData) {
                        if (!(i in newData)) newData[i] = {}
                        for (let k in respData[i]) {
                            newData[i][k] = respData[i][k]
                        }
                    }
                }
                return newData
            })
            props.changeView("Data from Excel file have been filled. If any data was invalid, then it was skipped. Please re-check Passenger Details and enter any data if missing. Press proceed to continue.")
            setUploadFile(null)
            MixPanel.track(MixPanel.types.agent.insurance.excel_uploaded)
            return true
        } else if (response.status === 413) {
            props.changeView("Max file size can be 5MB. Please check uploaded file.")
            return false
        } else {
            props.changeView(response.error || "API Error!")
            return false
        }
    }

    return (
        <div>
            <div className="py-8 px-8 bg-sk-yellow rounded-2xl flex gap-3 flex-col lg:flex-row text-sm lg:text-base">
                <div className="flex-1">
                    <p className="font-bold lg:text-lg">{selectedPlan.plan_name}</p>
                    <p className="font-semibold">{Constants.INSURANCE.TRIP_TYPE[searchData.trip_type]}</p>
                    <p className="font-semibold">{Constants.INSURANCE.DESTINATION_TYPE[searchData.destination_type]}</p>
                </div>
                <div className="flex-1">
                    <p className="font-semibold">Start Date: {searchData.departure_date}</p>
                    {searchData.return_date && <p className="font-semibold">Return Date: {searchData.return_date}</p>}
                    <p className="font-semibold">No. of Pax: {searchData.num_pax.length}</p>
                </div>
                <div className="flex-none">
                    <button type="button" className="bg-sk-blue rounded-2xl px-4 py-2 text-white text-sm font-semibold" onClick={() => props.changeView(null, 1, null, {})}>Choose another plan</button>
                </div>
            </div>
            <div className="mt-8 flex items-center lg:items-start flex-col-reverse lg:flex-row gap-8">
                <div className="flex-1">
                    <div className="flex items-center">
                        <p className="flex-1">
                            <span className="font-bold">Enter Passenger Details</span><br />
                            <span className="text-sm">For hassle free claims, please enter correct details of the travelling passenger only.</span>
                        </p>
                        <div></div>
                    </div>
                    {searchData.num_pax.map((x, i) => <div key={i}>
                        <Passenger i={i} x={x} searchData={searchData} updateData={passengerDataUpdate} data={data?.[i]} show={i === 0 ? true : false} copySection={copySection} hideAgentFunctionality={props.hideAgentFunctionality} />
                    </div>)}
                    {!props.hideAgentFunctionality && <p className="mt-4 mb-2 text-center"> -- OR --</p>}
                    {!props.hideAgentFunctionality && <CommonAccordion className="flex-1 mt-4 p-4" heading="Upload Excel File" show={false} id="sk-insurance-upload-excel" icon={<RiFileExcel2Fill className='h-6 w-6 fill-sk-blue inline mr-2' />}>
                        <div className="flex flex-col gap-4 items-center">
                            <div className="flex flex-col items-center gap-1">
                                <span className="text-sm font-medium">Upload Excel file to fill Passenger Details.</span>
                                <Link className='text-xs font-medium text-sk-blue underline underline-offset-2 cursor-pointer' to={insuranceStaticFile[searchData.destination_type]} target='_blank'>Download Sample File for {Constants.INSURANCE.DESTINATION_TYPE[searchData.destination_type]}</Link>
                            </div>
                            <CommonFormFileInput id="sk-insurance-upload-excel-file-input" label="Choose File" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={setUploadFile} />
                            <p className="text-xs text-center px-2">Note: Please do not change headings present in Row Number 1.<br />Add data from Row Number 2 onwards.</p>
                            <CommonFormButton text="Upload" short={true} className="px-4" onClick={onUpload} />
                        </div>
                    </CommonAccordion>}
                    <CommonFormButton text="Proceed to Review" className="w-full my-8" onClick={onSubmit} />
                </div>
                <div className="flex-none w-full lg:w-80 bg-sk-yellow rounded-t-2xl">
                    <p className="px-8 flex py-4 font-bold items-center">
                        <span className="flex-none text-lg">Price Details</span>
                        <span className="flex-grow"></span>
                    </p>
                    <div className="bg-gray-50 rounded-t-2xl px-8 flex justify-between pt-6 font-medium">
                        <span className="float-none flex">
                            Gross Premium
                            <CommonTooltip width="w-64" bottomLeft={true}>
                                <IoIosInformationCircleOutline className="text-black" />
                                <p className="mt-1">Gross premium of policy inclusive of 18% GST.</p>
                            </CommonTooltip>
                        </span>
                        <span className="flex-none">&#8377; {selectedPlan.premium}</span>
                    </div>
                    <div className="bg-gray-50 px-8 flex justify-between pt-6 font-medium">
                        <span className="float-none flex">
                            Discount
                            <CommonTooltip width="w-64" bottomLeft={true}>
                                <IoIosInformationCircleOutline className="text-black" />
                                <p className="mt-1">{selectedPlan.discountPercent}% commission on net premium (Gross premium - GST).</p>
                            </CommonTooltip>
                            &nbsp;( - )
                        </span>
                        <span className="flex-none">&#8377; {selectedPlan.discount}</span>
                    </div>
                    <div className="bg-gray-50 px-8 flex justify-between py-6 font-medium">
                        <span className="float-none flex">
                            TDS
                            <CommonTooltip width="w-64" bottomLeft={true}>
                                <IoIosInformationCircleOutline className="text-black" />
                                <p className="mt-1">2% of discount is deducted as TDS. To claim the TDS please write with your PAN number to insurance@sankash.in</p>
                            </CommonTooltip>
                            &nbsp;( + )
                        </span>
                        <span className="flex-none">&#8377; {selectedPlan.tds}</span>
                    </div>
                    <div className="px-8 flex py-6 justify-between font-bold">
                        <span className="float-none flex">
                            Net Payment
                            <CommonTooltip width="w-64" bottomLeft={true}>
                                <IoIosInformationCircleOutline className="text-black" />
                                <p className="mt-1">Only pay premium net of commission.</p>
                            </CommonTooltip>
                        </span>
                        <span className="flex-none">&#8377; {selectedPlan.net_amount}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PassengerDetails