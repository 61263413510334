import React, { useState } from 'react'
import { CommonFormButton, CommonLoader, CommonTable, Download } from '../../..'
import API from '../../../../apis'
import MixPanel from '../../../../config/mixpanel'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

const InsuranceConfirmation = (props) => {
    const [loading, setLoading] = useState(1)
    const [data, setData] = useState(null)
    const [message, setMessage] = useState("Please wait...")
    const [invoice, setInvoice] = useState(null)

    if (loading > 0) setTimeout(async function () {
        const response = await API.insurance.status(props.insuranceNumber, props.hideAgentFunctionality ? props.data.access_token : null)
        if (response.status === 200 && response.data?.data) {
            if (response.data.data === "GENERATED") {
                setMessage("Policy Generated. Fetching Details...")
                const response = await API.insurance.policy(props.insuranceNumber, isMobile, props.hideAgentFunctionality ? props.data.access_token : null)
                if (response.status === 200 && response.data?.data) {
                    setLoading(0)
                    setData(response.data.data)
                    if (response.data?.data?.invoice) {
                        setInvoice(response.data?.data?.invoice)
                    }
                    MixPanel.track(MixPanel.types.agent.insurance.policy_generated)
                }
            } else if (response.data.data === "IN_PROGRESS") {
                setLoading(prev => prev + 1)
                setMessage("Policy Generation in progress (" + loading + ")...")
            } else {
                setLoading(0)
                if (props.hideAgentFunctionality) {
                    setMessage(<p>We're facing issues while generating this policy at the moment. We'll keep trying to generate this policy in the background. Please contact insurance@sankash.in for your insurance policy with Insurance ID {props.insuranceNumber}.</p>)
                } else {
                    setMessage(<p>We're facing issues while generating this policy at the moment. We'll keep trying to generate this policy in the background. Please check <Link to="/agent/transactions?tab=travel-insurance" className='underline underline-offset-2 text-sk-blue font-medium'>Insurance Tab</Link> in My Transactions after a few minutes or contact your Relationship Manager.</p>)
                }
                MixPanel.track(MixPanel.types.agent.insurance.policy_error)
            }
        } else {
            setMessage(response.error || "API Error!")
        }
    }, 5000)

    async function actionsHandler(item, action) {
        const num = item * 1 - 1
        Download(data.data[num].downloadLink)
        MixPanel.track(MixPanel.types.agent.insurance.policy_download)
    }

    // async function cancelPolicy() { }

    async function downloadInvoice() {
        Download(invoice.downloadLink)
        MixPanel.track(MixPanel.types.agent.insurance.invoice_download)
    }

    return (
        <div>
            {loading >= 0 && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />{message}</p>}
            {data !== null && <div>
                <CommonTable data={data} actionsHandler={actionsHandler} nowrap={true} />
                <div className="mt-24 flex flex-row-reverse space-x-8">
                    {/* <CommonFormButton id="sk-ins-cancel-policy" text="Cancel Policy" isOutline={true} className="w-54" onClick={cancelPolicy} /> */}
                    {invoice && !props.hideAgentFunctionality && <CommonFormButton id="sk-ins-download-invoice" text="Download Invoice" className="w-full lg:max-w-96 lg:w-54 px-8" onClick={downloadInvoice} short={true} isOutline={true} />}
                </div>
            </div>}
        </div>

    )
}

export default InsuranceConfirmation